import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatCardModule } from '@angular/material/card'

import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCardModule, TranslateModule],
})
export class FooterComponent {
  year = new Date().getFullYear()
}
