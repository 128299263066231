<footer class="app-footer">
  <mat-card appearance="outlined" class="mat-elevation-z0">
    <div class="container text-center">
      <p>
        <a href="https://proximilar.com/terms-of-use/" target="_blank">{{ 'info.terms' | translate }}</a>
        &
        <a href="https://proximilar.com/privacy-policy/" target="_blank">{{ 'info.privacy' | translate }}</a>
      </p>
      <span>{{ 'info.copyright' | translate: { year: year } }}</span>
    </div>
  </mat-card>
</footer>
