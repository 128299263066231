<form class="search-form" (ngSubmit)="showForecasts()">
  <div class="search-input-wrapper">
    <mat-form-field class="form-field-sm form-field-reverse form-field-no-error">
      <mat-label>{{ 'forecast.searchBar.searchField' | translate }}</mat-label>
      <!-- [placeholder]="'forecast.searchBar.searchField' | translate" -->
      <input
        type="text"
        name="searchText"
        autocomplete="off"
        aria-label="Number"
        matInput
        [(ngModel)]="searchText"
        (input)="$search.next(searchText)"
        (focus)="$search.next(searchText)"
        (blur)="hideSearchDebounce$.next(null)"
        #search
      />

      <button type="button" matPrefix mat-icon-button aria-label="Search" class="search-btn" (click)="showForecasts()">
        <mat-icon>search</mat-icon>
      </button>

      <div matSuffix class="mat-suffix-wrapper" [class.in-request]="inRequest">
        <mat-spinner [diameter]="15"></mat-spinner>
        @if (searchText) {
          <button type="button" mat-icon-button aria-label="Clear" (click)="resetSearchForecast()">
            <mat-icon>close</mat-icon>
          </button>
        }
      </div>
    </mat-form-field>

    <div class="search-preview-data" [hidden]="!searchOptions.length">
      <div class="options">
        @for (company of searchOptions; track company) {
          <a
            (click)="chooseCompany.emit(company.ticker); $event.stopPropagation(); $event.preventDefault()"
            class="option option-ticker"
            [class.selected]="company.companyId === selectedCompanyId"
          >
            <b [innerHtml]="company.ticker | uppercase | selectSearch: searchText"></b>
          </a>
          <a
            (click)="chooseCompany.emit(company.ticker); $event.stopPropagation(); $event.preventDefault()"
            class="option option-name"
            [class.selected]="company.companyId === selectedCompanyId"
          >
            <span [innerHtml]="company.name | titlecase | selectSearch: searchText"></span>
          </a>
        }
      </div>
    </div>
  </div>

  @if (allowCurrentFolder && isAuth) {
    <mat-checkbox
      class="example-margin"
      name="inCurrentFolder"
      color="primary"
      [(ngModel)]="searchInCurrentFolder"
      (ngModelChange)="changeCurrentFolderOptions($event)"
      [disabled]="inRequest"
    >
      {{ 'searchBar.inCurrentFolder' | translate }}
    </mat-checkbox>
  }
</form>
