import { AsyncPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatProgressBarModule } from '@angular/material/progress-bar'

import { IUiFacade, UI_FACADE } from '~/core/features'

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressBarModule, AsyncPipe],
})
export class ProgressBarComponent {
  constructor(@Inject(UI_FACADE) public uiFacade: IUiFacade) {}
}
